<template>
  <div>
    <div class="pa-3">
      <v-col>
        <v-row dense>
          <h3 class="my-auto">DAFTAR RIWAYAT HIDUP</h3>
          <v-spacer></v-spacer>
          <!-- <v-avatar size="80">
              <img
                :src="foto"
                style="
                  width: 100px;
                  height: 100px;
                  background-position: center;
                  background-repeat: no-repeat;
                "
              />
            </v-avatar> -->
          <div class="circular--portrait">
            <img :src="foto" />
          </div>
        </v-row>
      </v-col>

      <v-divider class="my-3"></v-divider>

      <v-simple-table
        class="laporan my-border border-kanan"
        dense
        v-if="DataUtama"
      >
        <template v-slot:default>
          <tbody>
            <tr>
              <td>1.</td>
              <td colspan="2">Nama Lengkap</td>
              <td v-if="DataUtama">
                {{ DataUtama.nama }}
              </td>
            </tr>
            <tr>
              <td>2.</td>
              <td colspan="2">N.I.P</td>
              <td>
                {{ DataUtama.nipBaru }}
              </td>
            </tr>
            <tr>
              <td>3.</td>
              <td colspan="2">Pangkat dan Golongan Ruang</td>
              <td v-if="rwpangkat.golongan">
                {{ rwpangkat.golongan.nama }}
              </td>
            </tr>
            <tr>
              <td rowspan="3">4.</td>
              <td rowspan="3">Tempat / Tgl. Lahir</td>
              <td>a. Tempat Lahir</td>
              <td v-if="DataUtama.tempatLahir">
                {{ DataUtama.tempatLahir.nama }}
              </td>
            </tr>
            <tr>
              <td class="border-kiri">b. Kabupaten / Kota Lahir</td>
              <td v-if="DataUtama.tempatLahir">
                {{ DataUtama.tempatLahir.nama }}
              </td>
            </tr>
            <tr>
              <td class="border-kiri">c. Tanggal Lahir</td>
              <td>
                {{ DataUtama.tglLahir }}
              </td>
            </tr>
            <tr>
              <td>5.</td>
              <td colspan="2">Jenis Kelamin</td>
              <td v-if="DataUtama.jenisKelamin">
                {{ DataUtama.jenisKelamin.nama }}
              </td>
            </tr>
            <tr>
              <td>6.</td>
              <td colspan="2">Agama</td>
              <td v-if="DataUtama.agama">
                {{ DataUtama.agama.nama }}
              </td>
            </tr>
            <tr>
              <td>7.</td>
              <td colspan="2">Status Perkawinan</td>
              <td v-if="DataUtama.jenisKawin">
                {{ DataUtama.jenisKawin.status }}
              </td>
            </tr>
            <tr>
              <td rowspan="3">8.</td>
              <td rowspan="3">Pangkat / Golongan Terakhir</td>
              <td>a. Golongan</td>
              <td v-if="rwpangkat.golongan">
                {{ rwpangkat.golongan.nama }}
              </td>
            </tr>
            <tr>
              <td class="border-kiri">b. Nomor SK</td>
              <td v-if="rwpangkat.suratKeputusan">
                {{ rwpangkat.suratKeputusan.nomor }}
              </td>
            </tr>
            <tr>
              <td class="border-kiri">c. Masa Kerja</td>
              <td v-if="rwpangkat.masaKerjaGolongan">
                {{ rwpangkat.masaKerjaGolongan.tahun }} Tahun
                {{ rwpangkat.masaKerjaGolongan.bulan }} Bulan
              </td>
            </tr>
            <tr>
              <td rowspan="3">9.</td>
              <td rowspan="3">Diklat Struktural Terakhir</td>
              <td>a. Jenis Diklat</td>
              <td v-if="rwdiklat.latihanStruktural">
                {{ rwdiklat.latihanStruktural.nama }}
              </td>
            </tr>
            <tr>
              <td class="border-kiri">b. Nomor</td>
              <td v-if="rwdiklat">
                {{ rwdiklat.nomor }}
              </td>
            </tr>
            <tr>
              <td class="border-bawah border-kiri">c. Tanggal</td>
              <td class="border-bawah" v-if="rwdiklat">
                {{ rwdiklat.tanggal }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="mt-5">
        <h3>RIWAYAT JABATAN</h3>
      </div>

      <v-divider class="my-3"></v-divider>

      <v-data-table
        class="my-border laporan border-kanan border-bawah"
        :headers="headersJ"
        :items="rwjabatan"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:[`item.jabatanjenis`]="{ item }">
          {{ item.jabatan.jenis.nama }} <br />
          {{ item.eselon.nama }}
        </template>
        <template v-slot:[`item.jabatannama`]="{ item }">
          {{ item.namaJabatan }} <br />
          {{ item.namaUnor }}
        </template>
        <template v-slot:[`item.pejabat`]="{ item }">
          Pejabat : {{ item.suratKeputusan.pejabat }} <br />
          Nomor : {{ item.suratKeputusan.nomor }} <br />
          Tanggal : {{ item.suratKeputusan.tanggal }}
        </template>
      </v-data-table>

      <div class="mt-5">
        <h3>RIWAYAT PENDIDIKAN</h3>
      </div>

      <v-divider class="my-3"></v-divider>

      <v-data-table
        class="my-border laporan border-kanan border-bawah"
        :headers="headersP"
        :items="rwpendidikan"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:[`item.namasekolah`]="{ item }">
          {{ item.namaSekolah }} <br />
          {{ item.lokasiSekolah }} <br />
          {{ item.tahunLulus }}
        </template>
        <template v-slot:[`item.namakepsek`]="{ item }">
          {{ item.namaKepalaSekolah }} <br />
          {{ item.nomorIjasah }} <br />
          {{ item.tglLulus }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>
  
<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

export default {
  async mounted() {
    this.get_nip = this.$route.params.id;
    this.getData();
    this.getPangkat();
    this.getDiklat();
    this.getJabatan();
    this.getPendidikan();

    // setInterval(function () {
    //   window.print()
    // }, 1500)
  },

  data: () => ({
    token: Cookie.get("token"),

    nipBaru: "",
    namaPegawai: "",
    unor: "",
    foto: "",

    DataUtama: {},
    rwpangkat: {},
    rwdiklat: {},

    rwjabatan: [],
    rwpendidikan: [],

    headersJ: [
      {
        text: "Jenis Jabatan / Eselon",
        align: "start",
        value: "jabatanjenis",
        sortable: false,
      },
      {
        text: "Nama Jabatan / Unit Organisasi",
        value: "jabatannama",
        sortable: false,
      },
      {
        text: "Pejabat yang Menetapkan, Nomor dan Tanggal Surat Keputusan",
        value: "pejabat",
        sortable: false,
      },
      { text: "Pangkat", value: "golongan.nama", sortable: false },
      { text: "TMT", value: "jabatan.tmt", sortable: false },
    ],

    headersP: [
      {
        text: "Tingkat Pendidikan",
        align: "start",
        value: "pendidikan.nama",
        sortable: false,
      },
      {
        text: "Nama Sekolah / Perguruan Tinggi Tempat dan Tahun Lulus",
        value: "namasekolah",
        sortable: false,
      },
      {
        text: "Nama Kepsek Nomor dan Tanggal Ijazah",
        value: "namakepsek",
        sortable: false,
      },
    ],
  }),

  methods: {
    getData() {
      const url = process.env.VUE_APP_ASN + "datautama/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.nipBaru = response.data.mapData.DataUtama.nipBaru;
          this.namaPegawai = response.data.mapData.DataUtama.nama;
          this.unor = response.data.mapData.DataUtama.unor.nama;
          this.foto = response.data.mapData.DataUtama.foto;

          this.DataUtama = response.data.mapData.DataUtama;
        })
        .catch(function (error) {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    getPangkat() {
      const url = process.env.VUE_APP_ASN + "golongan/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.rwpangkat = response.data.mapData.RwGolongan.slice(-1)[0];
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    getDiklat() {
      const url = process.env.VUE_APP_ASN + "diklat/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.rwdiklat = response.data.mapData.RwDiklat.slice(-1)[0];
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    getJabatan() {
      const url = process.env.VUE_APP_ASN + "jabatan/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.rwjabatan = response.data.mapData.RwJabatan;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    getPendidikan() {
      const url = process.env.VUE_APP_ASN + "pendidikan/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          let arr = response.data.mapData.RwPendidikan;
          let hasil = arr.filter(function (el) {
            // console.log(el.pendidikan.tk.id);
            return el.pendidikan.tk.id >= 20;
          });

          this.rwpendidikan = hasil;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    print() {
      this.$router.push("/riwayathidup/print/" + this.nipBaru);
    },
  },
};
</script>
  